import React, { Component, useEffect, useState } from 'react';
import ButtonComponent from './cubeComponent/form/ButtonComponent';
import { BUTTON_TYPES } from './cubeComponent/constants/globalVariable';
import IconComponent from './cubeComponent/icons';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: null,
        };

        window.addEventListener('error', () => {
            this.setState({
                hasError: true,
            });
        });
    }

    static getDerivedStateFromError(error) {
        return {
            hasError: true,
        };
    }

    handlePageRefresh() {
        window.location.reload();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
          this.setState({ hasError: false });
        }
      }

    render() {
        if (this.state.hasError) {
            return (
                <div onClick={(e) => e.stopPropagation()} className="h-screen justify-center bg-white items-center flex gap-5 flex-col w-full">
                    <div className="flex flex-col gap-2 items-center justify-center">
                        <IconComponent cssClass="h-2/3" name="pageBreak" />
                        <span className="font-semibol text-2xl">An unexpected error occured</span>
                        <span> Our team has been notified about this and is working on a resolution.</span>
                        <span>Meanwhile please refresh and try again.</span>
                        <span>
                            {' '}
                            <ButtonComponent onClick={this.handlePageRefresh} buttonType={BUTTON_TYPES.BACKGROUND_BUTTON}>Refresh</ButtonComponent>
                        </span>
                    </div>
                </div>
            );
        }
      return this.props.children;
    }
  }
export default ErrorBoundary;
