import css from './UploadFileMinimiser.module.css';
import {
 useCallback, useEffect, useState, useRef,
} from 'react';
import arrowDown from '../../../assets/icons/svg/arrowDown.svg';
import close from '../../../assets/icons/svg/close.svg';
import maximize from '../../../assets/icons/svg/Maximize.svg';
import { useDispatch, useSelector } from 'react-redux';
import tickMark from '../../../assets/icons/svg/greenTickMark.svg';
import arrowUp from '../../../assets/icons/svg/ArrowsUp.svg';
import {
 maximiseFileUpload, removeFileUploadData, updateFileUploadData,
} from '../../../actions/fileUpload';
import { getFileImage } from '../../../services/utils/helpers';
import FileDelete from '../FileDelete/FileDelete';

const EXPANDED_VIEW = 'EXPANDED_VIEW';
const COMPACT_VIEW = 'COMPACT_VIEW';

const UploadFileMinimiser = ({ handleCloseFileUploadModal }) => {
  const [view, setView] = useState(COMPACT_VIEW);
  const dispatch = useDispatch();
  const { fileUploadList, fileUploadState, spaceData } = useSelector((state) => state);

  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] = useState(false);
  const [fileIndex, setFileIndex] = useState(null);
  const selectedFile = useRef(null);

  const [files, setFiles] = useState([]);

  useEffect(() => {
    setFiles(fileUploadList.filter((file) => !file.isDeleted && !file.isRemoved));
  }, [fileUploadList]);

  const handleArrowDown = () => {
    setView((previous) => {
      if (previous === COMPACT_VIEW) {
        return EXPANDED_VIEW;
      }
      return COMPACT_VIEW;
    });
  };

  const handleClose = () => {
    handleCloseFileUploadModal();
  };

  const getProgress = () => {
    const progress = (files.filter((file) => file.progressCompleted).length) / (files.length);
    return progress * 100;
  };

  const handleDeleteModalClose = () => {
    setOpenDeleteConfirmationModal(false);
  };

  const cancelUpload = (event) => {
    const name = event.currentTarget.attributes['data-set'].value;
    const index = fileUploadList.findIndex((file) => file.name == name);
    setFileIndex(index);
    selectedFile.current = fileUploadList[index];
    // setOpenDeleteConfirmationModal(true)
    dispatch(updateFileUploadData({
      index,
      data: {
        isRemoved: true,
      },
    }));
  };

  const handleMaximise = () => {
    dispatch(maximiseFileUpload());
  };

  const onDeleteFiles = () => [
    fileUploadList[fileIndex].controller.forEach((item) => item.abort()),
  ];

  const onDeleteSuccess = () => {
    handleDeleteModalClose();
    dispatch(removeFileUploadData(fileIndex));
  };

  const headerTemplate = () => (
    <div className="flex flex-col mb-2">
      <div className="flex flex-row justify-between">
        <p className="text-2xl">
          <strong>{`Uploading ${files.length} items`}</strong>
        </p>
        <div className="flex flex-row gap-4 items-center">
          <img src={maximize} className="h-5 w-5 cursor-pointer" alt="Maximize" onClick={handleMaximise} />
          <img src={view === EXPANDED_VIEW ? arrowDown : arrowUp} className="h-5 w-5 cursor-pointer" onClick={handleArrowDown} alt="Arrow Down" />
          <img src={close} className="h-4 w-4 cursor-pointer" onClick={handleClose} alt="Close" />
        </div>
      </div>
      <p className="text-base">
        {`Completed (${files.filter((file) => file.progressCompleted).length}/${files.length})`}
      </p>
    </div>
  );

  const uploadCompleted = () => (
    <div className="flex gap-1 items-center">
      <img src={tickMark} className="h-5 w-5" />
      <div className="text-base">Upload Complete</div>
    </div>
  );

  const uploadError = (error) => (
    <div className="text-xs text-red-500 error_text">{error}</div>
  );

  const getFilesProgress = () => (
<div>
      {files.map((file, fileIndex) => (
        <div key={`${file.name}_${fileIndex}`} className={css.fileDetails}>
          <div className="flex items-center mb-4 gap-2.5">
            <img src={getFileImage(file)} className={css.fileImage} />
            <div className="flex flex-col gap-2.5 grow">
              <div className="flex justify-between items-center">
                <p className="text-base font-bold">{file.name}</p>
                <img src={close} data-set={file.name} data-index={fileIndex} className="cursor-pointer h-2.5 w-2.5" onClick={cancelUpload} />
              </div>
              <div className={css.progessDiv}>
                {file.error ? uploadError(file.error) : file.progressCompleted ? uploadCompleted() : null}
                {file.duplicateFileStatus?.found === false && file.error === false && file.progressCompleted === false && file.triggerFileUpload && (
                  <div key={`progress_${file.name}`} className={css.progressWrapper}>
                    <div className={css.fileUploadStatusWrapper}>
                      <div>{file.status}</div>
                      <div>{`${file.progress == 100 ? (file.progressCompleted ? 100 : 99) : file.progress}%`}</div>
                    </div>
                    <div className={css.progressBody}>
                      <div className={css.progressArea} style={{ width: `${file.progress == 100 ? (file.progressCompleted ? 100 : 99) : file.progress}%` }} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
);

  return (
    <>
      {fileUploadState === 'MINIMISED' && files.length ? (
        <div className={css.root}>
          {view === EXPANDED_VIEW ? (
            <div className={css.uploadingFiles}>
              {headerTemplate()}
              {getFilesProgress()}
            </div>
          ) : (
            <div className={css.mainBox}>
              {headerTemplate()}
              <div className={css.progressBarContainer}>
                <div className={css.progressBar} style={{ width: `${getProgress()}%` }} />
              </div>
            </div>
          )}
        </div>
      ) : null}
      {openDeleteConfirmationModal && (
        <FileDelete
          workspaceId={spaceData.id}
          selectedFiles={[selectedFile.current].map((file) => {
            file.hierarchyId = file.fileId;
            return file;
          })}
          onCloseModal={handleDeleteModalClose}
          onDeleteSuccess={onDeleteSuccess}
          isFileUpload
          onDeleteFiles={onDeleteFiles}
        />
      )}
    </>
  );
};

export default UploadFileMinimiser;
