import { TextAlign } from "@syncfusion/ej2-grids";
import { AccumulationLabelPosition, ConnectorModel, ZoomSettingsModel } from "@syncfusion/ej2-react-charts";
import { TooltipSettingsModel } from "@syncfusion/ej2-react-gantt";
import { TitleSettingsModel, CenterPositionModel } from "@syncfusion/ej2-react-maps";
import { AccordionItemModel } from "@syncfusion/ej2-react-navigations";
import { ILoadedEventArgs, FontModel } from "@syncfusion/ej2-react-progressbar";
import { AxisModel } from "@syncfusion/ej2-react-spreadsheet";
import { ReactElement } from "react";

export interface TooltipProps {
  enable: boolean;
}

export interface SyncfusionBarChartProps {
  height?: string;
  id: string;
  style: any;
  primaryXAxis: AxisModel;
  primaryYAxis: AxisModel;
  width: string;
  load: (args: ILoadedEventArgs) => void;
  title: string;
  onChartLoad: (args: ILoadedEventArgs) => void;
  tooltip: TooltipProps;
  data: any;
}

export enum BorderStyleTypes {
  DOUBLE = "double",
  SOLID = "solid",
  GROOVE = "groove",
  DOTTED = "dotted",
  DASHED = "dashed",
  RIDGE = "ridge",
  INSET = "inset",
}

export enum MaskTypes {
  MOBILE = "000-000-0000",
  COUNTRY_ISO_CODE = "LL/LLL",
  DATE = "00/00/0000",
  PRODUCT_KEY = "AAAAA-AAAAA-AAAAA-AAAAA",
  DATE_FORMAT_2 = "00/LLL/0000",
}

export type ButtonComponentProps = {
  cssClass?: string;
  children?: string | ReactElement<any, any>;
  gradientColor1?: string;
  gradientColor2?: string;
  gradientTextColor1?: string;
  gradientTextColor2?: string;
  gradientBorderColor1?: string;
  gradientBorderColor2?: string;
  bgColor?: string;
  borderColor?: string;
  borderWidth?: string;
  borderType?: BorderStyleTypes;
  btnColor?: string;
  padding?: string;
  borderRadius?: string;
  fontWeight?: string;
  height?: string;
  width?: string;
  disabled?: boolean;
  readOnly?: boolean;
  onBlur?: (data?: any) => any;
  onFocus?: (data?: any) => any;
  onClick?: (data?: any) => any;
  buttonType?: string;
  type?: string;
  textAlign?: any;
};

export type AddIconComponentProps = {
  cssClass?: string;
  gradientTextColor1?: string;
  gradientTextColor2?: string;
  borderStyle?: BorderStyleTypes;
  borderWidth?: string;
  borderColor?: string;
  isDisabled?: boolean;
};

export interface SplitButtonItemsType {
  text?: string;
  iconCss?: string;
}
export type DropdownButtonItemsType = SplitButtonItemsType;
export interface SplitButtonComponentProps {
  beforeItemRender?: (data: any) => any;
  items?: SplitButtonItemsType[];
  isDisabled?: boolean;
  content?: string;
  iconCss?: string;
  color?: string;
  gradientColor1?: string;
  gradientColor2?: string;
  gradientTextColor1?: string;
  gradientTextColor2?: string;
  dropdownArrowBgColor?: string;
  bgColor?: string;
  barColor?: string;
  buttonType?: string;
  onBlur?: (data?: any) => any;
  onChange?: (data?: any) => any;
  onClick?: (data?: any) => any;
  onFocus?: (data?: any) => any;
  onSelect?: (data?: any) => any;
}

export interface DropdownButtonComponentProps {
  beforeItemRender?: (data: any) => any;
  items?: DropdownButtonItemsType[];
  children?: string | ReactElement<any, any>;
  isDisabled?: boolean;
  hideIcon?: boolean;
  iconCss?: string;
  color?: string;
  label?: string;
  height?: string;
  gradientColor1?: string;
  gradientColor2?: string;
  bgColor?: string;
  buttonType?: string;
  content?: string;
  popUpWidth?: string;
  width?: string;
  allowButtonPadding?: string;
  onSelect?: (data?: any) => any;
  onBlur?: (data?: any) => any;
  onChange?: (data?: any) => any;
  onClick?: (data?: any) => any;
  onFocus?: (data?: any) => any;
}

export interface DropdownListComponentProps {
  id?: string;
  isCountryFlag?: boolean;
  dataSource: any;
  textColor?: string;
  popupHeight?: string;
  popupWidth?: string;
  placeholder?: string;
  fields?: any;
  value: string;
  setValue?: (data: any) => void;
  width?: string;
  iconCss?: string;
  color?: string;
  label?: string;
  gradientColor1?: string;
  gradientColor2?: string;
  bgColor?: string;
  buttonType?: string;
  setBackgroundTransparent?: boolean;
  border?: string;
  fontWeightInput?: string;
  allowFlexGrow?: boolean
}

export interface ToggleSwitchComponentProps {
  id?: string;
  label?: string;
  isChecked?: boolean;
  isDisabled?: boolean;
  gradientColor1?: string;
  gradientColor2?: string;
  bgColor?: string;
  borderColor?: string;
  dragOnColor?: string;
  dragOffColor?: string;
  onBlur?: (data?: any) => any;
  onChange?: (data?: any) => any;
  onClick?: (data?: any) => any;
  onFocus?: (data?: any) => any;
}
export interface CheckboxComponentProps {
  checked?: boolean;
  label?: string;
  borderColor?: string;
  borderColorFocus?: string;
  gradientColor1?: string;
  gradientColor2?: string;
  bgColor?: string;
  name?: string;
  value?: any;
  isDisabled?: boolean;
  onBlur?: (data?: any) => any;
  onChange?: (data?: any) => any;
  onClick?: (data?: any) => any;
  onFocus?: (data?: any) => any;
  change?: (data?: any) => any;
}

export type RadioButtonComponentProps = CheckboxComponentProps;

export enum MultiSelectModesTypes {
  BOX = "Box",
  DELIMITER = "Delimiter",
  DEFAULT = "Default",
  CHECKBOX = "CheckBox",
}
export interface MultiSelectComponentProps {
  id?: string;
  dataSource?: any;
  mode?: MultiSelectModesTypes;
  fields?: any;
  placeholder?: string;
  enabled?: boolean;
  widthInPercentage?: string;
  margin?: string;
  disableChipBackground?: boolean,
  change?: (data?: any) => any;
  onChange?: (data?: any) => any;
  onClick?: (data?: any) => any;
  onFocus?: (data?: any) => any;
}
export interface TextboxComponentProps {
  placeholder?: string;
  widthInPercentage?: string;
  isDisabled?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  isWarning?: boolean;
  isRTL?: boolean;
  isSmall?: boolean;
  label?: string;
  margin?: string;
  name?: string;
  value?: string;
  type?: string;
  floatLabelType?: string;
  onChange?: (data?: any) => any;
  onFocus?: (data?: any) => any;
  onBlur?: (data?: any) => any;
  onKeyDown?: (data?: any) => any;
  onKeyUp?: (data?: any) => any;
  change?: (Data?: any) => any;
}

export interface TextboxMaskComponentProps {
  mask?: MaskTypes;
  isDisabled?: boolean;
  widthInPercentage?: string;
  margin?: string;
  label?: string;
  onChange?: (data?: any) => any;
  onFocus?: (data?: any) => any;
  onBlur?: (data?: any) => any;
}

export interface TextboxNumericComponentProps {
  format?: string;
  value?: number;
  min?: number;
  max?: number;
  step?: number;
  isDisabled?: boolean;
  widthInPercentage?: string;
  widthInPX?: string;
  margin?: string;
  showSpinButton?: boolean;
  onChange?: (data: any) => void;
}

export interface AutoCompleteComponentProps {
  id?: string;
  placeholder?: string;
  isEnabled?: boolean;
  widthInPercentage?: string;
  dataSource: any;
  change?: (e: any) => any;
}

export type ComboBoxComponentProps = AutoCompleteComponentProps & {
  popupHeight?: string;
  margin?: string;
  dataSource?: any;
  placeholder?: string;
  value?: string | number;
  isEnabled?: boolean;
};

export interface RichTextEditorComponentProps {
  id?: string;
  enableResize?: boolean;
  showCharCount?: boolean;
  enabled?: boolean;
  height?: string;
  width?: string;
  items?: string[];
  toolbarBgColor?: string;
  iconHoverBgColor?: string;
  iconHoverColor?: string;
  iconSelectedGradientColor1?: string;
  iconSelectedGradientColor2?: string;
  iconColor?: string;
  change?: (data?: any) => any;
  focus?: (data?: any) => any;
  blur?: (data?: any) => any;
  actionBegin?: (data?: any) => any;
  actionComplete?: (data?: any) => any;
  contentHeight?: string;
  padding? : string;
}

export interface IconComponentProps {
  name?: string;
  height?: string | number;
  cssClass?: string;
  className?: string;
  onClick?: (data?: any) => any;
}

export interface ListBoxComponentProps {
  onChange: (data?: any) => any;
  dataSource: any;
  selectedIndex?: any;
  listBoxStyle?: any;
}
export declare type TooltipPosition =
  | "TopLeft"
  | "TopCenter"
  | "TopRight"
  | "BottomLeft"
  | "BottomCenter"
  | "BottomRight"
  | "LeftTop"
  | "LeftCenter"
  | "LeftBottom"
  | "RightTop"
  | "RightCenter"
  | "RightBottom";

export interface TooltipComponentProps {
  content: string;
  position: TooltipPosition;
  children: string | ReactElement<any, any>;
  tipColor?: string;
  color?: string;
  bgColor?: string;
  fontSize?: string;
  width?: string;
  style?: any;
}

export interface FeedbackToastPositionProps {
  X: string;
  Y: string;
}

export declare type ToastType = "SUCCESS" | "WARNING" | "ERROR";

export interface FeedbackToastComponentProps {
  title?: string;
  position?: FeedbackToastPositionProps;
  onClose?: (data?: any) => any;
  onBeforeOpen?: (data?: any) => any;
  onBeforeClose?: (data?: any) => any;
  isNewestOnTop?: boolean;
  isActionButton?: boolean;
  isShowCloseButton?: boolean;
  timeOut?: number;
  isShowProgressBar?: boolean;
  actionBtnText?: string;
  onActionBtnClick?: (data?: any) => any;
  width?: number;
  onClick?: (data?: any) => any;
  progressBarColor?: string;
  id?: string;
  content?: string;
  type?: ToastType;
}

export interface CardComponentProps {
  dataBound?: any;
  cardBody?: string | ReactElement<any, any>;
  width?: string;
  background?: string;
  cardHeaderText?: string | ReactElement<any, any>;
  headerLink?: string;
  headerLinkText?: string;
  enableShareIcon?: boolean;
  maxMinIconName?: string;
  enableMaxMinIcon?: boolean;
  enableThreeDotsIcon?: boolean;
  items?: any;
  onClick?: any;
  selectDropDownItem?: any;
  onMaximizeClick?: (data?: any) => any;
  onViewCLick?: () => any;
  onListClick?: () => any;
  onItemsClick?: () => any;
}

export declare type AvatarSizeType = "XXS" | "SM" | "LSM" | "XS" | "LG" | "LLG" | "XL";
export interface AvatarComponentProps {
  isCircle?: boolean;
  isSquare?: boolean;
  size?: AvatarSizeType;
  children?: string | ReactElement<any, any>;
  bgColor?: string;
  style?: any;
}

export interface SliderComponentProps {
  onChange?: (data?: any) => any;
  onClick?: (data?: any) => any;
  gradientColor1?: string;
  gradientColor2?: string;
  handleGradientColor1?: string;
  handleGradientColor2?: string;
  handleShadowColor?: string;
  color?: string;
  enabled?: boolean;
  min?: number;
  max?: number;
  value?: number;
  width?: number | string;
}

export interface ModalStateDialogComponentProps {
  headerContent: string | ReactElement<any, any>;
  footerContent: string | ReactElement<any, any>;
  modelContent: string | ReactElement<any, any>;
  isVisible: boolean;
  width?: string;
  id?: string;
  ref?: any;
  disableDefaultFooter?: boolean;
  headerTitle?: string;
  onSave?: () => void;
  onCancel?: () => void;
  allowModalPadding?: boolean;
  padding?: any;
  zIndex?: number
}

export interface UserTagComponentProps {
  userId: string;
  isCircle?: boolean;
  borderRadius?: string;
  size?: any;
  iconBgColor?: string;
  userName?: string;
  profileUrl?: string;
  profilePicKey?: string;
  nameInitial?: string;
  fontSize?: string;
  userNameBackground?: string;
  showCrossIcon?: boolean,
  crossIconOnClick?: any
}

export interface TableComponentProps {
  width?: string;
  body?: any;
  headerColumns: GridHeaderColumnsType[];
  dataSource?: any;
  height?: any;
  field?: any;
  textAlign?: any;
  headerColor?: any;
  queryCellInfo?: any;
  background?: any;
  colorText?: any;
  borderColor?: any;
}

export interface GridHeaderColumnsType {
  field: string;
  headerText: string;
  width?: string;
  textAlign?: TextAlign;
  template?: any;
}

export interface DropdownIconComponentProps {
  dropDownOptions?: any;
  onClick?: (data?: any) => any;
  popUpWidth?: string;
  dataSource?: any;
}

export interface PieChartComponentsProps {
  dataSource: PieChartInfo[];
  dataLabel: PieChartLabelProps;
  pieTitle?: string;
  pieRadius?: string;
  pieExplode?: boolean;
  id: string;
  onTextRender?: (data: any) => any;
  onMouseClick?: (data: any) => any;
  pieRef?: any;
}

export interface PieChartInfo {
  pieXName?: string;
  pieYName?: Number;
  pieColor?: string;
}

export interface PieChartLabelProps {
  visible?: boolean;
  position?: AccumulationLabelPosition;
  connectorStyle?: ConnectorModel;
  font?: FontModel;
}

export type ShapeLayerType = "OSM" | "Geometry" | "Bing" | "GoogleStaticMap" | "Google"
export interface SparklineGraphComponentProps {
  id?: any;
  dataSource?: any;
  xName?: any;
  yName?: any;
}
export interface DragListComponentProps {
  data1?: any;
  data2?: any;
  fields?: any;
  setData1?: any;
  setData2?: any;
  itemTemplate?: any;
}

export interface AccordionComponentProps {
  children?: string | ReactElement<any, any>;
  bgColor?: string;
  normal?: string;
  allowBorder?: string;
  allowBorderPadding?: string
  allowEachDirectiveBorder?: string

}

export interface AccordionItemModelProps extends AccordionItemModel {
  props?: any;
}

export interface GridComponentProps {
  columnsDirectiveList?: any;
  injectServices: any;
  ref?: any;
  customGridStyle?: any;
}

export interface TreeGridComponentProps {
  columnsDirectiveList?: any;
  injectServices: any;
  ref?: any;
  toolbarWidth?: string;
  customGridStyle?: any;
  children?: string | ReactElement<any, any>;
}

export interface MapComponentProps {
  latitude?: any;
  longitude?: any;
  zoomFactor?: any;
  enable?: boolean;
  height?: any;
  width?: any;
  name?: any;
  isToolTipVisible?: any;
  mapOnClick?: (data: any) => any;
}