import { useEffect, useState } from 'react';
import axiosWrapper from '../../services/axiosWrapper';
import css from './index.module.css';
import ButtonComponent from '../../cubeComponent/form/ButtonComponent';
import { BUTTON_TYPES } from '../../cubeComponent/constants/globalVariable';
import SliderComponent from '../../cubeComponent/form/SliderComponent';
import { ReactComponent as DoubleTick } from '../../assets/images/doubleTick.svg';
import { showToastNotification } from '../../actions/toast';
import { useDispatch } from 'react-redux';
import { TOAST_TYPE_SUCCESS } from '../../services/constants';

export default function PaymentPlan({ redirectPortal, portalId, type = 'checkout' }) {
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const [recurringType, setRecurringType] = useState('month');

  const init = async () => {
    const response = await axiosWrapper.get(`${process.env.REACT_APP_CUBE_BACKEND}/payments/getProductsList`);
    setProducts(response.data.data.sort((a, b) => a.metadata.index - b.metadata.index));
  };

  useEffect(() => {
    init();
  }, []);

  const handleProductSubscribedClick = (status, selectedProductIndex) => {
    const productsList = products.map((product, index) => {
      let isSubscribedClick = false;
      if (index == selectedProductIndex) {
        isSubscribedClick = status;
      }
      return {
        ...product,
        isSubscribedClick,
      };
    });
    setProducts(productsList);
  };

  const handleSubscribeClick = async (e) => {
    const selectedProductIndex = e.target.attributes['data-index'].value;

    if (type == 'checkout') {
      // TODO: This will not work as backend have token chekc
      const response = await axiosWrapper.post(`${process.env.REACT_APP_CUBE_BACKEND}/payments/createCheckoutSession`, {
        priceId: products[selectedProductIndex].default_price,
        quantity: products[selectedProductIndex].quantity || 10,
        redirectPortal,
        portalId,
        productId: products[selectedProductIndex].id,
        plan: products[selectedProductIndex].name,
      });
      if (response.data.status) {
        window.open(response.data.data.url);
      }
    } else if (type == 'updateSubscription') {
      if (products[selectedProductIndex].isSubscribedClick) {
        const response = await axiosWrapper.post(`${process.env.REACT_APP_CUBE_BACKEND}/payments/updateSubscription`, {
          addQuantity: products[selectedProductIndex].quantity || 10,
        });
        if (response.data.status) {
          dispatch(
            showToastNotification({
              content: 'Update successfully',
              type: TOAST_TYPE_SUCCESS,
            }),
          );
        }
        handleProductSubscribedClick(false, selectedProductIndex);
      } else {
        handleProductSubscribedClick(true, selectedProductIndex);
      }
    }
  };

  const handleQuantityChange = (args) => {
    const productsList = products.map((product) => {
      if (product.isSubscribedClick) {
        return {
          ...product,
          quantity: args.value,
        };
      }
      return product;
    });
    setProducts(productsList);
  };

  return (
    <div className={css.root}>
      {products.map((product, index) => {
        let amount = 0;
        if (product.priceObj) {
          const filteredPriceObj = product.priceObj.filter((priceObj) => priceObj.recurring.interval == recurringType);
          amount = filteredPriceObj[0].unit_amount;
        }

        return (
          <div className={css.product}>
            <div className={css.header}>
              <span className={css.name}>{product.name}</span>
              <span className={css.description}>{product.description}</span>
            </div>
            <div className={css.priceWrapper}>
              {`$${amount / 100 || 0} `}
              <span className={css.recurringFrequency}>/per seat/per month</span>
            </div>
            <div className={css.quantityWrapper}>
              {product.isSubscribedClick && (
                <SliderComponent
                  type="MinRange"
                  value={10}
                  min={10}
                  max={90}
                  ticks={{
                    placement: 'After',
                    largeStep: 20,
                    smallStep: 1,
                    showSmallTicks: true,
                  }}
                  limits={{
                    enabled: true,
                    minStart: 10,
                    minEnd: 90,
                  }}
                  change={handleQuantityChange}
                />
              )}

              <ButtonComponent
                data-index={index}
                buttonType={BUTTON_TYPES.GRADIENT_BUTTON}
                onClick={handleSubscribeClick}
              >
                {type == 'checkout' && 'Subscribe'}
                {type == 'updateSubscription' && product.isSubscribedClick && `Add (+${product.quantity || 10}) seats`}
                {type == 'updateSubscription' && !product.isSubscribedClick && 'Upgrade'}
              </ButtonComponent>
            </div>
            <div className={css.featuresWrapper}>
              <span>This includes:</span>
              <div className={css.featuresList}>
                {product.metadata.features.split('###').map((feature) => (
                  <span className={css.individualFeature}>
                    <DoubleTick />
                    {feature}
                  </span>
                ))}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
