export const formCategories = {
    inspection: 'INSPECTION',
    progressUpdates: 'PROGRESS_UPDATES',
    generalForms: 'GENERAL_FORMS',
    changeOrder: 'CHANGE_ORDER',
    permitToWork: 'PERMIT_TO_WORK',
    payments: 'PAYMENTS',
    mailBox: 'MAILBOX',
    bills: 'BILLS',
    issues: 'ISSUES',
    projects: 'PROJECTS',
    files: 'FILES',
    contract: 'CONTRACT',
};
export const formCategoriesDataSource = [
    { name: 'Inspections', value: formCategories.inspection },
    { name: 'Progress Updates', value: formCategories.progressUpdates },
    { name: 'General Forms', value: formCategories.generalForms },
    { name: 'Change Orders', value: formCategories.changeOrder },
    { name: 'Payments', value: formCategories.payments },
    { name: 'Permit To Work', value: formCategories.permitToWork },
    { name: 'Mail Box', value: formCategories.mailBox },
    { name: 'Bills', value: formCategories.bills },
    { name: 'Issues', value: formCategories.issues },
    { name: 'Projects', value: formCategories.projects },
    { name: 'Files', value: formCategories.files },
    { name: 'Contract', value: formCategories.contract },
];

export const formCategoryObject = {
    [formCategories.inspection]: {
        formHeader: 'Inspection',
        titleLable: 'Inspection Title',
        descriptionLable: 'Inspection Description',
        modalHeader: 'Create Inspection',
        urlKey: 'forms',
        tabTitle: 'Inspections',
        activeCell: 'Inspections',
        selectedTab: 'inspection',
        listUrlKey: 'inspectionFormsList',
        allowedSections: ['blankSection', 'tableSection', 'presetSection', 'quantities', 'WBSItems', 'linkForm', 'references', 'sectionFromLibrary', 'tasks', 'milestones'],
    },

    [formCategories.progressUpdates]: {
        formHeader: 'Progress Update',
        titleLable: 'Progress Title',
        descriptionLable: 'Progress Description',
        modalHeader: 'Create Progress Updates',
        urlKey: 'forms',
        tabTitle: 'Progress and Updates',
        activeCell: 'Progress Updates',
        selectedTab: 'progressUpdates',
        listUrlKey: 'progressUpdatesFormsList',
        allowedSections: ['blankSection', 'tableSection', 'presetSection', 'quantities', 'WBSItems', 'linkForm', 'references', 'sectionFromLibrary', 'tasks', 'milestones'],
    },

    [formCategories.generalForms]: {
        formHeader: 'General Form',
        titleLable: 'Form Title',
        descriptionLable: 'Form Description',
        modalHeader: 'Create General Forms',
        urlKey: 'forms',
        tabTitle: 'General Forms',
        activeCell: 'General Forms',
        listUrlKey: 'generalFormsList',
        allowedSections: ['blankSection', 'tableSection', 'presetSection', 'linkForm', 'references', 'sectionFromLibrary'],
    },
    [formCategories.mailBox]: {
        formHeader: 'Mail Box',
        titleLable: 'Form Title',
        descriptionLable: 'Form Description',
        modalHeader: 'Mail Box',
        urlKey: 'forms',
        tabTitle: 'Mail Box',
        activeCell: 'Mailbox',
        listUrlKey: 'mailBoxFormsList',
        allowedSections: ['blankSection', 'tableSection', 'sectionFromLibrary'],
    },
    [formCategories.bills]: {
        formHeader: 'Bill',
        titleLable: 'Bill Title',
        descriptionLable: 'Bill Description',
        modalHeader: 'Create Bill',
        urlKey: 'forms',
        selectedTab: 'bills',
        tabTitle: 'Bills',
        activeCell: 'Bills',
        listUrlKey: 'billsFormsList',
        allowedSections: ['blankSection', 'tableSection', 'presetSection', 'quantities', 'WBSItems', 'linkForm', 'references', 'sectionFromLibrary', 'tasks'],
    },
    [formCategories.changeOrder]: {
        formHeader: 'Change Order',
        titleLable: 'Change Order Title',
        descriptionLable: 'Change Order Description',
        modalHeader: 'Create Change Order',
        urlKey: 'forms',
        selectedTab: 'changeOrder',
        tabTitle: 'Change Order',
        activeCell: 'Change Orders',
        listUrlKey: 'changeOrderFormsList',
        allowedSections: ['blankSection', 'tableSection', 'presetSection', 'quantities', 'WBSItems', 'linkForm', 'references', 'sectionFromLibrary', 'tasks', 'milestones'],
    },
    [formCategories.payments]: {
        formHeader: 'Payments',
        titleLable: 'Payment Title',
        descriptionLable: 'Payment Description',
        modalHeader: 'Create Payment',
        urlKey: 'forms',
        selectedTab: 'payments',
        tabTitle: 'Payments',
        activeCell: 'Payments',
        listUrlKey: 'PaymentsFormsList',
        allowedSections: ['blankSection', 'presetSection', 'tableSection', 'linkForm', 'references', 'sectionFromLibrary'],
    },
    [formCategories.permitToWork]: {
        formHeader: 'Permit To Work',
        titleLable: 'Permit To Work Title',
        descriptionLable: 'Permit To Work Description',
        modalHeader: 'Create Permit To Work',
        urlKey: 'forms',
        selectedTab: 'PermitToWork',
        tabTitle: 'Permit To Work',
        activeCell: 'Permit To Work',
        listUrlKey: 'PermitToWorkFormsList',
        allowedSections: ['blankSection', 'presetSection', 'tableSection', 'linkForm', 'references', 'sectionFromLibrary'],
    },
    [formCategories.issues]: {
        formHeader: 'Issues',
        titleLable: 'Form Title',
        descriptionLable: 'Form Description',
        modalHeader: 'Issues',
        urlKey: 'forms',
        tabTitle: 'Issues',
        activeCell: 'Issues',
        listUrlKey: 'issuesFormsList',
        allowedSections: ['blankSection', 'tableSection', 'sectionFromLibrary'],
    },
    [formCategories.projects]: {
        formHeader: 'Projects',
        titleLable: 'Form Title',
        descriptionLable: 'Form Description',
        modalHeader: 'Projects',
        urlKey: 'forms',
        tabTitle: 'Projects',
        activeCell: 'Projects',
        listUrlKey: 'projectsFormsList',
        formType: 'projects',
        allowedSections: ['blankSection', 'sectionFromLibrary'],
        singleTemplate: true,
        defaultSection: {
            questionFields: [{
                attachmentsData: [],
                fieldHeading: 'Single Line',
                fieldType: 'single-line',
                questionValue: 'ID',
            }, {
                attachmentsData: [],
                fieldHeading: 'Single Line',
                fieldType: 'single-line',
                questionValue: 'Name',
            }],
            sectionHeading: 'ProjectDetails',
        },
    },
    [formCategories.files]: {
        formHeader: 'Files',
        titleLable: 'Form Title',
        descriptionLable: 'Form Description',
        modalHeader: 'Files',
        urlKey: 'forms',
        tabTitle: 'Files',
        activeCell: 'Files',
        listUrlKey: 'filesFormsList',
        formType: 'files',
        allowedSections: ['blankSection', 'sectionFromLibrary'],
    },
    [formCategories.contract]: {
        formHeader: 'Contract',
        titleLable: 'Title',
        descriptionLable: 'Description',
        modalHeader: 'Create Contract Custom Fields',
        urlKey: 'forms',
        selectedTab: '',
        tabTitle: '',
        activeCell: '',
        listUrlKey: '',
        allowedSections: ['blankSection', 'sectionFromLibrary'],
    },
};
export const presetSections = ['Quantities', 'References', 'Forms', 'Tasks', 'Milestones', 'WBS Items'];

export const presetSectionsObject = {
    quantity: 'Quantities',
    references: 'References',
    linkForm: 'Forms',
    tasks: 'Tasks',
    milestones: 'Milestones',
    WBSItems: 'WBS Items',
};
export const presetSectionsCodeObject = {
    quantity: 1,
    references: 2,
    linkForm: 3,
    tasks: 4,
    milestones: 5,
    tableSection: 6,
    WBSItems: 7,
};

export const formTemplateSource = {
    projectSettings: 'project_settings',
    portalSettings: 'portal_settings',
};
export const presetSectionsHeadingObject = {
    Quantities: 'quantity',
    References: 'references',
    Forms: 'linkForm',
    Tasks: 'tasks',
    Milestones: 'milestones',
    'WBS Items': 'WBSItems',
};
