export const updateSupportChatProperties = (userDetails = {}, portalData = {}, closeCallback = null) => {
    if (process.env.REACT_APP_CHAT_URL) {
        if (window.fcWidget) {
            if (userDetails.id) {
                window.fcWidget.setExternalId(userDetails.id);
                window.fcWidget.user.setFirstName(userDetails.firstName);
                window.fcWidget.user.setEmail(userDetails.email);
            }

            if (portalData.id) {
                window.fcWidget.user.setProperties({
                    portalName: portalData.name,
                });
            }
            window.fcWidget.on('widget:closed', () => {
                document.getElementById('fc_frame').style.display = 'none';
                if (closeCallback) {
                    closeCallback();
                }
            });
            document.getElementById('fc_frame').style.display = 'block';
            window.fcWidget.open();
        } else {
            setTimeout(updateSupportChatProperties, 1000);
        }
    }
};
